window.$ = window.jQuery = require("jquery");

require("bootstrap");

// import * as mdb from "mdb-ui-kit"; // lib
// import { Modal } from "mdb-ui-kit"; // module

window.owlCarousel = require("owl.carousel");
function HasArabicCharacters(text) {
    var arregex = /[\u0600-\u06FF]/;
    return arregex.test(text);
}
$(function () {
    var divs = document.getElementsByTagName("p");
    for (var index = 0; index < divs.length; index++) {
        if (HasArabicCharacters(divs[index].textContent)) {
            divs[index].className = "rtl";
        }
    }
});
