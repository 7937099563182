/* overflow more menu */
(function ($) {
    $.fn.ofmNavbar = function () {
        if (this.length) {
            var contentBar = this.find(".ofm-context-bar"),
                dropdown = this.find(".ofm-dropdown-content"),
                more = this.find(".ofm-dropdown-link"),
                link = this.find("a.ofm-link"),
                selected = "";

            $(window).resize(update);

            window.addEventListener("click", function (e) {
                if ($(e.target).parent().hasClass("ofm-dropdown-link")) {
                    dropdown.slideToggle(200);
                } else {
                    dropdown.slideUp(200);
                }
            });

            contentBar.on("click", link, function () {
                linkClick.call(this, "More");
            });
            dropdown.on("click", link, function () {
                linkClick.call(this);
            });
            update();

            function linkClick(val) {
                contentBar.children().removeClass("active");
                this.classList.add("active");
                dropdown.slideUp(200);
                update();
            }

            function update() {
                var offsetTop = contentBar.offset().top;
                dropdown.empty();
                more.hide();
                contentBar.children().each(function (i, a) {
                    if ($(a).offset().top > offsetTop) {
                        if (a.innerHTML == selected) {
                            a.classList.add("active");
                            more.html(a.innerHTML).addClass("active");
                        }
                        dropdown.append($(a).clone());
                        more.show();
                    } else if (a.innerHTML == more.html()) {
                        return false;
                    }
                });
            }
        }
    };
})(jQuery);

$("#ofmNavbar").ofmNavbar();

// accordian

$(document).ready(function () {
    $(".footer-tree")
        .find("li")
        .each(function (e) {
            // console.log(this);
            if ($(this).children("ol").length) {
                $(this).children("a").addClass("accordion");
                $(this).children("ol").addClass("accordion-content");
            }
        });

    $(".accordion").click(function (e) {
        e.preventDefault();

        var $this = $(this);

        if ($this.children().hasClass("bi-chevron-compact-down")) {
            $this
                .children(".bi-chevron-compact-down")
                .removeClass("bi-chevron-compact-down")
                .addClass("bi-chevron-compact-up"); //Adds 'a', removes 'b'
        } else {
            $this
                .children(".bi-chevron-compact-up")
                .removeClass("bi-chevron-compact-up")
                .addClass("bi-chevron-compact-down"); //Adds 'a', removes 'b'
        }

        if ($this.next().hasClass("show")) {
            $this.next().removeClass("show");
            $this.next().slideUp(350);
        } else {
            $this
                .parent()
                .parent()
                .find(".accordion-content")
                .removeClass("show");
            $this.parent().parent().find(".accordion-content").slideUp(350);
            $this.next().toggleClass("show");
            $this.next().slideToggle(350);
        }
    });

    $(".accordion").each(function (i) {
        $(this).append(' <i class="bi bi-chevron-compact-down"></i>');
    });
});
/* end overflow more menu */

$(document).ready(function () {
    $(".owl-carousel").owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsiveClass: true,
        dots: false,
        rewind: true,
        stagePadding: 20,
        responsive: {
            0: {
                items: 1,
                nav: false,
            },
            600: {
                items: 2,
                nav: false,
            },
            900: {
                items: 3,
                nav: false,
            },
        },
    });
});

var imgs = document.querySelectorAll("img");
for (var i = 0; i < imgs.length; i++) {
    if (
        imgs[i].hasAttribute("height") &&
        imgs[i].hasAttribute("width").length
    ) {
        var aspectRatio =
            (imgs[i].getAttribute("height") / imgs[i].getAttribute("width")) *
            100;

        var div = document.createElement("div");
        div.style.paddingBottom = aspectRatio + "%";
        imgs[i].parentNode.insertBefore(div, imgs[i]);
        div.appendChild(imgs[i]);
    }
}
